@import url("../node_modules/dialog-polyfill/dist/dialog-polyfill.css");
@import url("../node_modules/bootstrap/dist/css/bootstrap.css");

:root {
  --bs-body-bg: #202124;
  --bs-body-color: #e8eaed;
  --brand-main: #d701c8; /*#ff03ee*/
  --brand-dark: #9c0091;
  --brand-alt: #5b11ac;
  --brand-alt-light: #6f1dc8;
  --approve-color: #0c7e0d;
  --approve-light: #0ea70f;
  --reject-color: #9c0000;
  --reject-light: #cf0808;
}

.btn-primary {
  background-color: var(--brand-main);
  border-color: var(--brand-dark);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--brand-dark);
  border-color: var(--brand-dark);
}

.btn-purple {
    background-color: var(--brand-alt);
    color: #fff;
  }
  
  .btn-purple:hover {
    color: #fff;
    background: var(--brand-alt-light);
  }

.card {
  color: var(--bs-body-bg);
}

a.btn,
a.btn:visited {
  color: #fff;
}

body {
  background: var(--bs-body-bg);
}

a:link,
a:visited {
  color: var(--brand-main);
}

a:hover,
a:focus {
  color: var(--bs-body-color);
}

label {
  color: var(--brand-main);
}

.notice {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: var(--brand-main);
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-sizing: border-box;
  }