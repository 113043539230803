@import url("./styles.css");

.product-card {
  background: var(--bs-body-color);
  display: flex;
  flex-direction: column;
  height: 120px;
  border-radius: 6px;
  justify-content: space-between;
  padding: 0;
}

.product-card--actions {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--bs-gray-600);
}

.product-card--actions > :first-child {
  border-radius: 0px 0px 0px 6px;
  border-right: 1px solid var(--bs-gray-600);
}

.product-card--actions > :last-child {
  border-radius: 0px 0px 6px 0px;
}

.product {
  display: flex;
}

.product--description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product--img {
  border-radius: 6px;
  margin: 10px;
  width: 54px;
  height: 54px;
}

.product--description-price,
.product--description-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  margin: 0;
}

.product--description-price {
  opacity: 0.5;
}

.product--quantity {
  margin-left: auto;
  margin-right: 10px;
  align-self: center;
}

.product--quantity .form-control {
  text-align: center;
  border: 1px solid var(--bs-gray-600);
}

.checkout-button {
  height: 36px;
  color: white;
  width: 100%;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0.6;
  border-radius: 0 0 6px 6px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.checkout-title {
  color: var(--brand-main);
}

.checkout-dialog {
  max-height: 800px;
  max-width: 800px;
  width: 100%;
  border: 2px solid var(--brand-main);
  border-radius: 6px;
}
